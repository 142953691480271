// src/App.js
import React, { useState, useEffect } from 'react';
import { db, functions } from './firebase'; // Import Firestore and Functions instance
import { collection, addDoc } from 'firebase/firestore'; // Import Firestore functions
import { httpsCallable } from 'firebase/functions'; // Import httpsCallable to call cloud functions
import './App.css'; // Import Tailwind CSS styles

const App = () => {

  // State to manage form data
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  // State to manage loading state
  const [isLoading, setIsLoading] = useState(false);

  // State to manage submission status
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Load the reCAPTCHA v3 script
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    // Cleanup: Remove the script when component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isLoading) return;
    setIsLoading(true);
    setError(null);

    try {
      console.log('Form submitted. Attempting to subscribe to Mailchimp...');
      if (!window.grecaptcha) {
        throw new Error('reCAPTCHA not loaded');
      }

      const token = await window.grecaptcha.execute(process.env.REACT_APP_RECAPTCHA_SITE_KEY, { action: 'submit' });

      const verifyRecaptchaAndRegister = httpsCallable(functions, 'verifyRecaptchaAndRegister');
      const result = await verifyRecaptchaAndRegister({
        name,
        email,
        recaptchaToken: token,
      });

      console.log('Firebase Function result:', result.data);

      await addDoc(collection(db, 'interests'), {
        name,
        email,
        recaptchaToken: token,
      });

      
      await subscribeToMailchimp(name, email);

      setSubmitted(true);
      setName('');
      setEmail('');
    } catch (error) {
      console.error('Error during submission:', error);
      let errorMessage = 'There was an error submitting the form. Please try again.';
      if (error.message) {
        errorMessage += ` Error details: ${error.message}`;
      }
      setError(errorMessage);
    } finally {
      setIsLoading(false);
    }
  };

  const subscribeToMailchimp = async (name, email) => {
    console.log('subscribeToMailchimp function called with:', { name, email });
    const subscribeToMailchimp = httpsCallable(functions, 'subscribeToMailchimp');

    try {
      console.log('Calling Cloud Function subscribeToMailchimp...');
      const result = await subscribeToMailchimp({ name, email });
      console.log('Cloud Function result:', result.data);
      console.log(result.data.message);
    } catch (error) {
      console.error('Error calling subscribeToMailchimp function:', error);
      console.error('Error subscribing to Mailchimp:', error);
      if (error.details) {
        console.error('Detailed error:', error.details);
      }
      throw new Error(`Failed to add user to Mailchimp. ${error.message || ''} ${error.details || ''}`);
    }
  };


  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-black text-white">
      <header className="mb-8">
        <img src="logo.png" alt="Logo" className="w-[300px] h-auto" />
      </header>
      <div className="relative p-8 bg-white bg-opacity-10 backdrop-blur-lg rounded-lg shadow-xl w-96">
        {submitted ? (
          <h2 className="text-center text-2xl font-bold text-white">
            Thank you for registering your interest!
          </h2>
        ) : (
          <>
            <h2 className="text-center text-2xl font-bold text-white">
              Register your interest
            </h2>
            <br />
            <p className="text-center text-gray-200 mb-4">
              We're currently in the final stages of developing the SafetyAssistance app.
            </p>
            <p className="text-center text-gray-200 mb-4">
              Register your email for regular updates and introductory offers.
            </p>
            <form onSubmit={handleSubmit}>
              <input
                className="w-full p-3 mb-4 bg-white bg-opacity-30 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                disabled={isLoading}
              />
              <input
                className="w-full p-3 mb-4 bg-white bg-opacity-30 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isLoading}
              />
              <button
                className="w-full bg-gradient-to-r from-blue-500 to-orange-500 p-3 rounded-lg text-white hover:opacity-90 transition-opacity disabled:opacity-60 disabled:cursor-not-allowed"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? 'Submitting...' : 'Register Interest'}
              </button>
            </form>
          </>
        )}

        {/* Loading Spinner */}
        {isLoading && (
          <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
            <div className="w-16 h-16 border-4 border-t-4 border-gray-200 rounded-full animate-spin"></div>
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
